import { convertArrayToObject } from '@root/common/utils/helpers';
import type { SectionRaw, SectionTargetRaw, BlockRaw, Feed, Section, SectionTarget, Block, BlockNativeAttributes, Article } from '@root/modules/feed/types';

export class FeedAdapter {
  private _pageContent: SectionRaw[];
  private _sectionIndex: number;
  private _blockIndex: number;

  constructor(originalData: SectionRaw[]) {
    this._pageContent = originalData;
    this._sectionIndex = 0;
    this._blockIndex = 0;
  }

  private adaptSection(sectionContent: SectionRaw): Section {
    const { attributes, blocks, targeting } = sectionContent;
    const convertedAttributes = convertArrayToObject<Section['attributes']['settings']>(attributes);
    const convertedTargeting = this.adaptSectionTargeting(targeting);

    const section: Section = {
      attributes: {
        settings: convertedAttributes,
      },
      blocks: [],
      targeting: convertedTargeting,
    };

    if (!blocks.items?.length) {
      return section;
    }

    for (const block of blocks.items) {
      const adaptedBlock = this.adaptBlock(block);
      section.blocks.push(adaptedBlock);
    }

    return section;
  }

  private adaptBlock(block: BlockRaw): Block {
    const mergedBlockAttributes = convertArrayToObject<BlockNativeAttributes>(block.attributes);

    const { blockData, ...settings } = mergedBlockAttributes;
    const attributes: Block['attributes'] = {
      blockIndex: this._blockIndex++,
      sectionIndex: this._sectionIndex,
    };

    if (settings) {
      attributes.settings = settings;
    }

    if (blockData) {
      attributes.data = blockData;
    }

    const blockContent: Block = {
      id: block.id,
      type: block.type,
      attributes,
      data: {
        items: this.blockArticles(block.blockArticles.items),
      },
    };
    return blockContent;
  }

  private blockArticles(items: BlockRaw['blockArticles']['items']): Article[] {
    const articles: Article[] = [];

    if (!items) {
      return articles;
    }

    for (const item of items) {
      if (!item.article) {
        continue;
      }

      const blockArticle = item.article;

      const article: Article = {
        ...blockArticle,
        // Overwrite manually changed article attributes in page builder admin
        content: {
          title: {
            text: item.title?.text || blockArticle.content.title.text || '',
          },
          lead: {
            text: item.lead?.text || blockArticle.content.lead?.text || '',
            content: blockArticle.content.lead?.content || [],
          },
          subtitle: {
            text: item.subtitle?.text || blockArticle.content.subtitle?.text || '',
          },
          paywall: blockArticle.content.paywall,
          activeExperiment: item.activeExperiment,
        },
        metaImage: item.metaImage || blockArticle.metaImage,
      };

      if (item.relatedArticles?.length) {
        const relatedArticles = item.relatedArticles.map((item) => item.article);
        article.relatedArticles = {
          items: [],
        };
        article.relatedArticles.items = [...relatedArticles];
      }
      articles.push(article);
    }
    return articles;
  }

  public adapt() {
    const feed: Feed = [];

    for (const section of this._pageContent) {
      const adaptedSection = this.adaptSection(section);
      feed.push(adaptedSection);
      this._sectionIndex++;
    }

    return feed;
  }

  private adaptSectionTargeting(targeting: SectionTargetRaw | null): SectionTarget {
    const { screenSize = null, userType = null } = targeting || {};

    return {
      screenSize,
      userType,
    };
  }
}
