
import LazyLoader from './lazy-loader/LazyLoader';
import ContentLayout from '@root/common/components/layout/ContentLayout.vue';
import HeadlineBlocks from '@root/common/components/base/headline/HeadlineBlocks.vue';

import { isJSON } from '@root/common/utils/isJSON';

import type { Feed, Section, Block } from '@root/modules/feed/types';
import { TrackingData } from '@root/common/types/fragment';

interface Props {
  feed: Feed;
}

interface Methods {
  getSectionSettings: (settings: Section['attributes']['settings']) => {
    insertBannerAfterSection: boolean;
    insertBannerTower: boolean;
    styles: string | null;
    classes: Record<string, boolean>;
  };
  skipLazyLoad: (block: Block) => boolean;
  getTrackingData: (section: Section, block: Block) => TrackingData | undefined;
  isSectionShown: (section: Section) => boolean;
}

const lazyLoader = new LazyLoader();
lazyLoader.setObserverSettings({ threshold: 0.1, rootMargin: '0px 0px 300px 0px' });
lazyLoader.setPreLoadedDomIds([0, 1, 2, 3, 4]);
lazyLoader.setLazyLoaderSettings({ dataId: 'section-index' });

const LazyLoaderVue = lazyLoader.create();

export default LazyLoaderVue.extend<unknown, Methods, unknown, Props>({
  components: {
    ContentLayout,
    HeadlineBlocks,
  },
  props: {
    feed: {
      type: Array,
      required: true,
    },
  },
  methods: {
    skipLazyLoad(block) {
      return Boolean(block.attributes.skipLazyload || block.attributes.settings?.skipLazyLoad);
    },
    getSectionSettings(settings) {
      const bannerPlacement = settings.bannerPlacement;
      const insertBannerAfterSection = bannerPlacement === 'bottom' || bannerPlacement === 'bottom_and_side';
      const insertBannerTower = bannerPlacement === 'side' || bannerPlacement === 'bottom_and_side';
      const customAdsAlgorithm = isJSON(bannerPlacement) ? JSON.parse(bannerPlacement) : null;
      const styles = settings.background ? `background-color: ${settings.background}` : null;
      const groupClass = { 'group-has-bg': !!settings.background && settings.background !== 'transparent' && settings.background !== '#00000000' };

      return {
        styles,
        classes: groupClass,
        insertBannerAfterSection,
        insertBannerTower,
        customAdsAlgorithm,
      };
    },
    getTrackingData(section, block) {
      if (!this.$channelConfig('features').blockTracking?.enable) {
        return;
      }

      const trackingData: TrackingData = {
        pageType: this.$route.name || 'unknown pageType',
        siteName: this.$channelConfig('settings').name,
        blockIndex: block.attributes?.blockIndex || 0,
        blockType: block.type,
        sectionIndex: block.attributes?.sectionIndex || 0,
        abTestGroup: this.$abTest?.getGroup(),
      };

      if (block.id) {
        trackingData.blockId = block.id;
      }

      return trackingData;
    },
    isSectionShown(section) {
      if (!section.targeting?.userType) {
        return true;
      }
      const isLoggedIn = this.$store.state.piano?.isLoggedIn;

      if (section.targeting.userType === 'AUTHENTICATED') {
        return isLoggedIn;
      } else if (section.targeting.userType === 'UNAUTHENTICATED') {
        return !isLoggedIn;
      }

      return true;
    },
  },
});
